import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_FLAG,
  GET_FLAGS,
  SEARCH_FLAG,
  STORE_FLAG,
  UPDATE_FLAG
} from "@/store/modules/flag.module";

export default {
  computed: {
    ...mapGetters(['flags', 'flagsMeta', 'flag', 'flagError', 'searchFlagList', 'searchFlagListMeta'])
  },
  methods: {
    getFlag(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FLAG, id),
      actions
      );
    },
    getFlags(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('flagBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('flagBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_FLAGS, {page, title}),
        actions
      );
    },
    storeFlag(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_FLAG, formData),
        actions
      );
    },
    updateFlag(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_FLAG, formData),
      actions
      );
    },
    searchFlag(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(SEARCH_FLAG, {title, page}),
      actions
      );
    },
  }
}
