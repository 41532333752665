<template>
  <div class="navigation-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-title" :label="`${titleForViewType} ماۋزوسى `" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`${titleForViewType} ماۋزوسىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="title-live-feedback">
            {{ titleForViewType }} ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="viewType === 'navigation'" sm="6" md="3">
        <b-form-group id="input-group-background" label="ماۋزو رەڭ نۇمۇرى" label-for="title-color">
          <b-input-group>
            <b-form-input id="background" placeholder=" ماۋزو رەڭگىنى تاللاڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" style="direction: ltr" v-model="formData.color" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.color || ''" @change="changeTitleColor" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
          <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" placeholder=" تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group :state="validateState('target')" aria-describedby="target-live-feedback" id="input-group-2" :label="`${titleForViewType} تۈرى `" :description="optionDesc" label-for="target">
          <b-form-radio-group id="target" v-model="formData.target" @change="changeTarget" :options="targetOptions" buttons name="radios-btn-default" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ titleForViewType }} تۈرىنى تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="formData.target">
      <b-col sm="6" md="3">
        <b-form-group :label="targetLabel" >
          <b-input-group>
            <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="shouldChoose ? targetTitle : formData.to" :style="{direction: shouldChoose ? 'rtl' : 'ltr'}" :placeholder="feedBackLabel" :readonly="shouldChoose" />
            <b-input-group-append v-if="shouldChoose">
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film'" @click="onClickOpenSearchFilmModal"> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film_category'" @click="onClickOpenSearchCategoryModal"> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film_flag'" @click="onClickOpenSearchFlagModal"> تاللاڭ </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="target-live-feedback">{{ feedBackLabel }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.target === 'mp'" sm="6" md="2">
        <b-form-group id="input-group-target-option" label="appid">
          <b-form-input style="direction: ltr" id="appid" v-model="formData.target_option" :state="validateState('target_option')" type="text" aria-describedby="target_option-live-feedback" placeholder="ئەپچاق AppId سىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="target_option-live-feedback">
            ئەپچاق AppId سىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group :state="validateState('navigation_image')" aria-describedby="target-live-feedback" id="input-group-navigation_image" :label="uploaderLabel" label-for="navigation_image">
          <image-uploader :check-size="viewType === 'banner'" width="750" height="320" v-model="formData.navigation_image" :upload-form="{strategy: viewType}" :accepted-file-types="acceptedFileTypes" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ uploaderLabel }} تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <search-film-modal v-model="filmModalState" @changed="onChangeFilm" :selected="formData.target === 'film' ? formData.to : null" />
    <search-category-modal v-model="categoryModalState" @changed="onChangeFilmCategory" :selected="formData.target === 'film_category' ? formData.to : null" />
    <search-flag-modal v-model="flagModalState" @changed="onChangeFilmFlag" :selected="formData.target === 'film_flag' ? formData.to : null" />
  </div>
</template>

<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import navigationMixin from "@m@/navigation.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import "@c@/plugins/vue-color-picker";
  import {sprintf} from "@c@/helpers/helpers";
  import searchFilmModal from "@v@/components/modals/searchFilmModal";
  import searchCategoryModal from "@v@/components/modals/searchFilmCategoryModal";
  import searchFlagModal from "@v@/components/modals/searchFilmFlagModal";
  export default {
    name: "NavigationForm",
    mixins: [ validationMixin, validateStateMixin, navigationMixin, sweetAlertMixin ],
    components: { ImageUploader, searchFilmModal, searchCategoryModal, searchFlagModal },
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
      viewType: {
        type: String,
        default: ()=> 'banner',
        validator: function (value) {
          // 这个值必须匹配下列字符串中的一个
          return ['banner', 'navigation'].includes(value)
        }
      }
    },
    validations(){
      let validates = {
        title: { required },
        navigation_image: { required },
        target: { required },
        to: { required },
        target_option: { required: requiredIf((formData)=>{
          return formData.target === 'mp';
        })}
      };
      if ( this.viewType === 'banner' ){}else if ( this.viewType === 'navigation' ){
        // validates['color'] = { required };
      }
      return {
        formData: validates
      }
    },
    computed: {
      toLabel(){
        switch (this.formData.target) {
          case 'film_category':
            return 'تۈرىنى تاللاڭ';
          case 'film_flag':
            return 'سەھىپە تاللاڭ';
          case 'film':
            return 'فىلىم تاللاڭ';
          case 'mp':
            return 'ئەپچاق بەت ئادېرسى';
          case 'external_link':
            return 'سىرتقى ئۇلانما ئادېرسى';
        }
      },
      titleForViewType(){
        switch (this.viewType) {
          case "banner":
            return "ئېلان"
          case "navigation":
            return "تىزىملىك"
        }
      },
      optionDesc(){
        let str = "%s رەسىمىنى چەككەندە %s  ئاتلايدۇ";
        switch (this.formData.target) {
          case "film":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن فىلىمگە ")
          case "film_category":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن فىلىم تۈرلىرىگە ")
          case "film_flag":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن سەھىپىگە ")
          case "mp":
            return sprintf(str, this.titleForViewType, "ئۇچۇرى تولدۇرۇلغان ئەپچاققا ")
          case "external_link":
            return sprintf(str, this.titleForViewType, "تولدۇرۇپ بەرگەن سىرتقى ئۇلانمىغا ")
        }
      },
      uploaderLabel(){
        switch (this.viewType) {
          case "banner":
            return "ئېلان رەسىمى"
          case "navigation":
            return "تىزىملىك سىنبەلگىسى"
        }
      },
      acceptedFileTypes(){
        switch (this.viewType) {
          case "banner":
            return "image/png, image/gif, image/jpg, image/jpeg"
          case "navigation":
            return "image/png, image/gif"
        }
      },
      targetLabel(){
        switch (this.formData.target) {
          case "film":
            return "فىلىم تاللاڭ"
          case "film_category":
            return "فىلىم تۈرىنى تاللاڭ"
          case "film_flag":
            return "فىلىم سەھىپىسىنى تاللاڭ"
          case "mp":
            return "ئەپچاق بەت ئادېرسى"
          case "external_link":
            return "ئۇلانما ئادېرسىنى تولدۇرۇڭ"
        }
      },
      feedBackLabel(){
        let str = "سول تەرەپتىكى كونۇپكىنى بېسىپ %s تاللاڭ";
        let str_1 = "%s كىرگۈزۈڭ";
        switch (this.formData.target) {
          case "film":
            return sprintf(str, "فىلىم")
          case "film_category":
            return sprintf(str, "فىلىم تۈرى")
          case "film_flag":
            return sprintf(str, "فىلىم سەھىپىسى")
          case "mp":
            return sprintf(str_1, "ئەپچاق 页面路径 سىنى")
          case "external_link":
            return sprintf(str_1, "تور بەت ئادېرسىنى")
        }
      },
      shouldChoose(){
        return ['film', 'film_category', 'film_flag'].includes(this.formData.target)
      }
    },
    created() {
      if (this.action === 'edit'){
        this.getNavigation(this.modelId)
          .then(({ data: navigation })=>{
            this.formData = navigation;
            if ( navigation.navigationable ){
              this.updateTargetTitle(navigation.navigationable.hasOwnProperty('title') ? navigation.navigationable.title : '');
            }
          })
      }
    },
    mounted() {
      this.initViewType();
    },
    data(){
      return {
        formData: {
          view_type: "",
          title: '',
          color: null,
          background: null,
          target: '',
          to: '',
          target_option: null,
          sort: '',
          icon: null,
          is_show: true
        },
        targetOptions: [
          { text: 'فىلىم', value: 'film' },
          { text: 'تۈر', value: 'film_category' },
          { text: 'سەھىپە', value: 'film_flag' },
          { text: 'ئەپچاق', value: 'mp' },
          { text: 'سىرتقى ئۇلانما', value: 'external_link' },
        ],
        targetTitle: null,
        filmModalState: false,
        categoryModalState: false,
        flagModalState: false,
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          this.storeNavigation(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateNavigation(this.formData);
        }
      },
      changeTitleColor(e){
        this.formData.color = e;
      },
      changeTarget(e){
        this.formData.to = null;
        this.targetTitle = null;
      },
      initViewType(){
        if ( this.viewType ){
          this.formData.view_type = this.viewType;
        }
      },

      toggleSomeModal(toggleType = 'filmModalState'){
        this[toggleType] = ! this[toggleType];
      },
      onClickOpenSearchFilmModal(){
        this.toggleSomeModal();
      },

      updateSomeFormData(key = 'to', val = null){
        this.formData[key] = val;
      },
      updateTargetTitle(targetTitle = null){
        this.targetTitle = targetTitle;
      },

      onChangeFilm(e){
        let { film } = e;
        this.updateTargetTitle(film.title);
        this.updateSomeFormData('to', film.id);
        this.$nextTick(()=> {
          this.toggleSomeModal();
        });
      },

      onClickOpenSearchCategoryModal(){
        this.toggleSomeModal('categoryModalState');
      },
      onChangeFilmCategory(e){
        let { category } = e;
        this.updateTargetTitle(category.title);
        this.updateSomeFormData('to', category.id);
        this.$nextTick(()=> {
          this.toggleSomeModal('categoryModalState');
        });
      },
      onClickOpenSearchFlagModal(){
        this.toggleSomeModal('flagModalState');
      },
      onChangeFilmFlag(e){
        let { flag } = e;
        this.updateTargetTitle(flag.title);
        this.updateSomeFormData('to', flag.id);
        this.$nextTick(()=> {
          this.toggleSomeModal('flagModalState');
        });
      }
    }
  }
</script>
