<template>
  <base-modal :id="id" :title="title" :modal-busy="filmBusy" :size="size" v-model="modalState" >
    <pagination :meta="searchFilmListMeta" :search-width="50" place-holder="فىلىم نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh" />
    <b-row>
      <b-col v-for="(film, index) in searchFilmList" :key="film.id" cols="4" md="2" class="mb-4">
        <film-item :index="index" :item="film" :selected="currentSelected === film.id" @changed="onChangeFilmItem" />
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import FilmItem from "@v@/components/items/FilmItem";
  import pagination from "@v@/components/paginate";

  import filmMixin from "@m@/film.mixin";
  import filmBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "searchFilmModal",
    components: { baseModal, FilmItem, pagination },
    props: {
      id: {
        type: String,
        default: () => 'search-film-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'فىلىم ئىزدەڭ'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      selected: {
        type: [String, Number]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      currentSelected(){
        if (this.selectedForComponentEvent){
          return this.selectedForComponentEvent
        }else if (this.selected){
          return this.selected
        }
        return null;
      },
    },
    mixins: [ filmMixin, filmBusyMixin ],
    created() {
      this.searchFilm(1);
    },
    data(){
      return {
        filmBusy: false,
        page: 1,
        film_title: '',
        selectedForComponentEvent: null
      };
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page;
        this.film_title = title;
        this.searchFilm(page, title);
      },
      onRefresh(title){
        this.searchFilm(1, title);
      },
      onChangeFilmItem(e){
        let { index, film } = e;
        this.updateSelectedForComponentEvent(film.id);
        this.$emit('changed', { film });
      },
      updateSelectedForComponentEvent(selectedForComponentEvent = null){
        this.selectedForComponentEvent = selectedForComponentEvent;
      }
    }
  }
</script>

<style scoped>

</style>
