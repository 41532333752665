<template>
  <portlet clean-body-class="p-3">
    <template slot="cleanBody">
      <div @click="onChange" class="category-item-wrapper d-flex align-items-center justify-content-center">
        <div class="item-wrapper-media position-relative">
          <b-avatar rounded size="50px" text="!" :src="item.icon_image" />
          <div v-if="selected" class="selected-state-wrapper position-absolute d-flex align-items-center justify-content-center">
            <i class="fa fa-check-circle text-white"></i>
          </div>
        </div>
        <div class="item-wrapper-info flex-grow-1 d-flex flex-column justify-content-center ml-2">
          <div class="item-wrapper-info-item">{{ item.title }}</div>
          <div class="item-wrapper-info-item description text-black-50">{{ item.films_count }} دانە فىلىم بار</div>
        </div>
      </div>
    </template>
  </portlet>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  export default {
    name: "CategoryItem",
    components: { Portlet },
    props: {
      item: {
        type: Object,
        default: ()=> {return {}}
      },
      index: {
        type: [Number, String],
        required: true
      },
      selected: {
        type: Boolean
      }
    },
    methods: {
      onChange(e){
        let { index, item } = this;
        this.$emit('changed', {index, category: item});
      }
    }
  }
</script>

<style scoped>
  .item-wrapper-info-item.description {
    font-size: 12px;
  }

  .selected-state-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .selected-state-wrapper i.fa {
    font-size: 42px;
  }
</style>
