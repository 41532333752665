<template>
  <base-modal :id="id" :title="title" :modal-busy="categoryBusy" :size="size" v-model="modalState" >
    <pagination :meta="searchCategoryListMeta" :search-width="50" place-holder="تۈر نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh" />
    <b-row>
      <b-col v-for="(category, index) in searchCategoryList" cols="6" :key="category.id">
        <category-item :index="index" :selected="currentSelected === category.id" :item="category" @changed="onChangeCategoryItem" />
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import pagination from "@v@/components/paginate";
  import CategoryItem from "@v@/components/items/CategoryItem";

  import categoryMixin from "@m@/category.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";

  export default {
    name: "searchFilmCategoryModal",
    components: { baseModal, pagination, CategoryItem },
    props: {
      id: {
        type: String,
        default: () => 'search-film-category-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'تۈر ئىزدەڭ'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      selected: {
        type: [String, Number]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      currentSelected(){
        if (this.selectedForComponentEvent){
          return this.selectedForComponentEvent
        }else if (this.selected){
          return this.selected
        }
        return null;
      },
    },
    mixins: [ categoryMixin, formBusyMixin ],
    created() {
      this.searchCategory('', 1);
    },
    data(){
      return {
        categoryBusy: false,
        page: 1,
        category_title: '',
        selectedForComponentEvent: null
      };
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page;
        this.category_title = title;
        this.searchCategory(title, page);
      },
      onRefresh(title){
        this.searchCategory(title, 1);
      },
      onChangeCategoryItem(e){
        let { index, category } = e;
        this.updateSelectedForComponentEvent(category.id);
        this.$emit('changed', { category });
      },
      updateSelectedForComponentEvent(selectedForComponentEvent = null){
        this.selectedForComponentEvent = selectedForComponentEvent;
      }
    }
  }
</script>

<style scoped>

</style>
