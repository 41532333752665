import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_CATEGORIES,
  GET_CATEGORY, SEARCH_CATEGORY, SET_HIDDEN_FILMS,
  SET_VISIBLE_FILMS,
  STORE_CATEGORY,
  UPDATE_CATEGORY
} from "@/store/modules/category.module";

export default {
  computed: {
    ...mapGetters(['categories', 'categoriesMeta', 'category', 'categoryError', 'searchCategoryList', 'searchCategoryListMeta'])
  },
  methods: {
    getCategory(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_CATEGORY, id),
      actions
      );
    },
    getCategories(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('categoryBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('categoryBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_CATEGORIES, {page, title}),
        actions
      );
    },
    storeCategory(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_CATEGORY, formData),
        actions
      );
    },
    updateCategory(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_CATEGORY, formData),
      actions
      );
    },
    setCategoryFilmsVisibleOrHidden(id, column, action = 1, actions = {
      begin: ()=>{ this.showBusy('categoryBusy'); debugConsole({message: action === 1 ? 'visible ...' : 'hidden ...'}); },
      after: (response, state)=>{ this.hideBusy('categoryBusy'); debugConsole({message: action === 1 ? 'visible' : 'hidden'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(action === 1 ? SET_VISIBLE_FILMS : SET_HIDDEN_FILMS, {id, column}),
      actions
      );
    },
    searchCategory(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(SEARCH_CATEGORY, {title, page}),
        actions
      );
    },
  }
}
