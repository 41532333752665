import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  CANCEL_PUSH_UP_FILM,
  GET_FILM, GET_FILM_DETAIL,
  GET_FILM_POSTER,
  GET_FILMS, PUSH_UP_FILM,
  SEARCH_FILM,
  STORE_FILM,
  UPDATE_FILM, UPDATE_FILM_POSTER
} from "@/store/modules/film.module";

export default {
  computed: {
    ...mapGetters(['films', 'filmsMeta', 'film', 'filmError', 'searchFilmList', 'searchFilmListMeta', 'filmPoster', 'filmDetail', 'filmPage'])
  },
  methods: {
    getFilm(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM, id),
      actions
      );
    },
    getFilmDetail(id, payload = {}, actions = {
      begin: ()=>{ this.showBusy(); },
      after: (response, state)=>{ this.hideBusy(); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM_DETAIL, { id, payload }),
      actions
      );
    },
    getFilms(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('filmBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('filmBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_FILMS, {page, title}),
        actions
      );
    },
    storeFilm(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_FILM, formData),
        actions
      );
    },
    updateFilm(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_FILM, formData),
      actions
      );
    },
    searchFilm(page = '', title = '', actions = {
      begin: ()=>{ this.showBusy('filmBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('filmBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(SEARCH_FILM, {page, title}),
        actions
      );
    },
    getFilmPoster(actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM_POSTER),
      actions
      );
    },
    updateFilmPoster(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_FILM_POSTER, formData),
      actions
      );
    },
    pushUpFilm(id, actions = {
      begin: ()=>{ this.showBusy('filmBusy'); debugConsole({message: 'pushing'}); },
      after: (response, state)=>{ this.hideBusy('filmBusy'); debugConsole({message: 'filmBusy'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(PUSH_UP_FILM, id),
      actions
      );
    },
    cancelPushUpFilm(id, actions = {
      begin: ()=>{ this.showBusy('filmBusy'); debugConsole({message: 'pushing'}); },
      after: (response, state)=>{ this.hideBusy('filmBusy'); debugConsole({message: 'filmBusy'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(CANCEL_PUSH_UP_FILM, id),
      actions
      );
    },
  }
}
