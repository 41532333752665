<template>
  <div class="advert-home position-relative">
    <b-overlay :show="navigationBusy">
      <pagination :meta="navigationsMeta" place-holder="ئېلان ماۋزوسىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'advert.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="navigation in navigations" :key="`navigation_${navigation.id}`" md="3" sm="6">
          <portlet body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="title"><span></span></template>
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="navigation.is_show" @change="onChangeSwitch( navigation.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'advert.edit', params: {id: navigation.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(navigation.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head" style="margin-top: -37px;">
                    <div class="kt-widget__media">
                      <b-avatar rounded text="!" :src="navigation.preview" size="50" />
                    </div>
                    <div class="kt-widget__info text-truncate">
                      <a class="kt-widget__username ">
                        {{ navigation.title }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تۈرى: </span>
                        <span class="kt-widget__data">{{ targets[navigation.target] }}</span>
                      </div>
                      <template v-if="['film', 'film_category', 'film_flag'].includes(navigation.target)">
                        <div class="kt-widget__contact">
                          <span class="kt-widget__label">{{ targets_title[navigation.target]}}</span>
                          <span class="kt-widget__data">{{ navigation.hasOwnProperty('navigationable') && navigation.navigationable ? navigation.navigationable.title: 'نامەلۇم' }}</span>
                        </div>
                      </template>
                      <template v-if="['external_link'].includes(navigation.target)">
                        <div class="kt-widget__contact">
                          <span class="kt-widget__label">ئۇلانما ئادېرسى: </span>
                          <span class="kt-widget__data">
                            <a :href="navigation.to" target="_blank" class="kt-font-info"><i class="fa fa-link"></i> بېسىپ كۆرۈڭ </a>
                          </span>
                        </div>
                      </template>
                      <template v-if="['mp'].includes(navigation.target)">
                        <div class="kt-widget__contact">
                          <span class="kt-widget__label"></span>
                          <span class="kt-widget__data text-white">❀❀❀</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!navigations.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئېلان قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'advert.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">ئېلان قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import navigationMixin from "@/mixins/navigation.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_NAVIGATION_ERROR} from "@/store/modules/navigation.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [navigationMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        navigationBusy: false,
        targets: {
          film: 'فىلىمغا ئاتلايدۇ',
          film_category: 'فىلىم تۈرىگە ئاتلايدۇ',
          film_flag: 'فىلىم سەھىپىسىگە ئاتلايدۇ',
          mp: 'ئەپچاققا ئاتلايدۇ',
          external_link: 'سىرتقى ئۇلانمىغا ئاتلايدۇ',
        },
        targets_title: {
          film: 'فىلىم نامى: ',
          film_category: 'تۈر نامى: ',
          film_flag: 'سەھىپە نامى: ',
        },
        page: 1,
        title: ''
      };
    },
    computed: {},
    created() {
      this.getNavigations(1, '');
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئېلان باشقۇرۇش', route: {name: 'advert.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق ئېلانلار'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getNavigations(page, title);
      },
      onRefresh(title){
        this.getNavigations(1, title)
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'navigations',
          id, column, mutationType: SET_NAVIGATION_ERROR,
          begin: ()=>{ this.showBusy('navigationBusy') },
          after: ()=>{ this.hideBusy('navigationBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر ئېلاننى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_NAVIGATION_ERROR, resource: 'navigations',
                begin: ()=>{ this.showBusy('navigationBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getNavigations(page, title);
                  this.hideBusy('navigationBusy');
                },
              })
            }
          }
        });
      }
    }
  }
</script>

<style scoped>
  .item-container {
    width: 100%;
  }

  .item-title {
    height: calc(1.25rem + .5rem);
    width: 100%;
  }

  .item-image-wrapper{
    width: 100%;
    max-height: 200px;
    height: 200px;
  }
</style>
