import {
  GET_NAVIGATION,
  GET_NAVIGATIONS,
  STORE_NAVIGATION,
  UPDATE_NAVIGATION
} from "@/store/modules/navigation.module";
import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
export default {
  computed: {
    ...mapGetters(['navigations', 'navigationsMeta'])
  },
  methods: {
    getNavigations(page = 1, title = '', view_type = 'banner', actions = {
      begin: ()=>{ this.showBusy('navigationBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('navigationBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_NAVIGATIONS, {page, title, view_type}),
        actions
      );
    },
    getNavigation(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_NAVIGATION, id),
      actions
      );
    },
    storeNavigation(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_NAVIGATION, formData),
        actions
      );
    },
    updateNavigation(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_NAVIGATION, formData),
      actions
      );
    }
  }
}
